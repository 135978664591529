<template>
    <div>
        <v-card v-bind:class="{ElementFilterCard:true, CustomCard: true, shiftedCard: addFormOpen}">
            <v-card-title class="filterTitle">Filtrer <v-icon style="margin-left:1%" @click="closeElementFilter">mdi-close</v-icon></v-card-title>
            <!-- <input type="text" placeholder="Filtrer les communes" class="input" v-model="input" @keyup="search()"> -->
            <div class="filterHeader">
                <div> <v-card-subtitle class="filterSubtitle">Communes</v-card-subtitle> </div>
                <div> <v-text-field @input="search" v-model="input" solo dense color="orange darken-2" class="searchBar" prepend-inner-icon="mdi-magnify"
                        label="...">
                    </v-text-field> </div>
            </div>
            <ul class="checkboxes">
                <li class="checkbox" v-for="commune in communes" :key="commune.nom">
                    <input type="checkbox" :value="commune.nom" v-model="selected" @change="handleSelect">
                    <label class="labelChecklist" :for="commune.nom">{{ commune.nom }}</label>
                </li>
            </ul>
            <br>
            <div class="btnZone"> <v-btn small color="orange darken-3" :outlined="isOutlined" class="btnChecklist"
                    @click="changeCheckState">{{ this.checkState }}</v-btn>
            </div>
            <br>
            <ul class="communesSelected">
                <li class="communeLi" v-for="select in selected" :key="select" v-show="selected.length < 8"
                    @click='del($event)' style="cursor: pointer; color: black">{{ select }} </li>
            </ul>

            <div class="filterHeader">
                <v-card-subtitle class="filterSubtitle">Catégories</v-card-subtitle>
            </div>
            <div class="filterControls">

                <ul class="checkboxes">
                    <li class="checkbox" v-for="categorie in categories" :key="categorie">
                        <input type="checkbox" :value="categorie" v-model="categorieSelected"
                            @change="handleCategorieSelect" />
                        <label class="labelChecklist" :for="categorie">{{ categorie }}</label>
                    </li>
                </ul>
                <br>
                <div class="btnZone"> <v-btn small color="orange darken-3" :outlined="isOutlinedbis" class="btnChecklist"
                        @click="selectAllCategories">{{ this.allCategories }}</v-btn> </div>

            </div>


        </v-card>
    </div>
</template>
  
<script>
import { store, mutations } from './Store';
import { bus } from '../main';
export default {
    data() {
        return {
            communes: [],
            selected: [],
            checkState: "Tout cocher",
            input: "",
            isOutlined: true,
            isOutlinedbis: true,
            communesNonFiltered: [],
            categorieSelected: [],
            categories: [
                "Agrotoponyme",
                "Anthropotoponyme",
                "Ecotoponyme",
                "Ethnotoponyme",
                "Géotoponyme",
                "Hagiotoponyme",
                "Hydrotoponyme",
                "Idéotoponyme / Toponymes abstrait",
                "Métaphore",
                "Mythotoponyme",
                "Néotoponyme",
                "Odonyme",
                "Orotoponyme",
                "Phènomènes atmosphèriques",
                "Phytotoponyme",
                "Toponyme Historique",
                "Toponyme Obscur",
                "Toponyme transféré",
                "Toponymes religieux",
                "Zootoponyme",
            ],
            allCategories: "Tout cocher"

        }

    },
    methods: {
        del(event) {
            let e = event.target.innerText;
            //let delCommune = e.trim().substring(0, e.length - 4);
            let delCommune = e.trim();
            let arr = this.selected.filter(element => {
                return element != delCommune
            })
            this.selected = arr;
            this.handleSelect();
        },
        search() {
            this.communes = [];
            if (this.input.length == 0) this.communes = this.communesNonFiltered;
            this.communes = this.communesNonFiltered.filter((item) => {
                return item.nom.toLowerCase().substring(0, this.input.length) == this.input.toLowerCase();
            })
        },
        disabledCategoriesFilter() {
            let filterControls = document.querySelector(".filterControls");
            filterControls.classList.add("disabled");
        },

        notDisabledCategoriesFilter() {
            let filterControls = document.querySelector(".filterControls");
            filterControls.classList.remove("disabled");
        },
        changeCheckState() {
            if (this.checkState == "Tout cocher") {
                this.checkState = "Tout décocher";
                this.selected = []
                this.isOutlined = false;
                this.communes.forEach(commune => {
                    this.selected.push(commune.nom);
                })
                this.handleSelect();
            }
            else if (this.checkState == "Tout décocher") {
                this.selected = [];
                this.checkState = "Tout cocher";
                this.isOutlined = true;
                this.handleSelect();
            }
        },
        setCommunes(communes) {
            mutations.setcommunes(communes);
        },
        handleSelect() {
            if (this.selected.length == 0) this.checkState = "Tout cocher";
            else this.checkState = "Tout décocher";
            this.setCommunes(this.selected);
            bus.$emit("communesFilter");
            this.input = "";
            this.communes = this.communesNonFiltered;

        },
        selectAllCategories() {

            if (this.allCategories == "Tout cocher") {
                this.allCategories = "Tout décocher";
                this.categorieSelected = []
                this.categories.forEach(categorie => {
                    this.categorieSelected.push(categorie);
                })
                this.isOutlinedbis = false;
                this.handleCategorieSelect();
            }
            else if (this.allCategories == "Tout décocher") {
                this.categorieSelected = [];
                this.isOutlinedbis = true;
                this.allCategories = "Tout cocher";
                this.handleCategorieSelect();



            }
        },
        handleCategorieSelect() {
            if (this.categorieSelected.length == 0) {
                this.getToponymesByCommunes();
                return;
            }
            this.locationsByCategorie.length = 0;
            var locationsByCategorieLocal = [];
            var temp = [];
            console.log(this.locationsByCommunesNonFiltered)
            this.locationsByCommunesNonFiltered.forEach((location) => {
                temp = [];
                this.categorieSelected.forEach((categorie) => {
                    location[1].forEach((l) => {
                        if (l.properties.CATEGORIE == categorie && !temp.includes(l)) temp.push(l);
                        else if (l.properties.CATEGORIE == null && !temp.includes(l)) temp.push(l);
                    });
                });
                locationsByCategorieLocal.push([location[0], temp]);
            });
            this.locationsByCategorie = locationsByCategorieLocal;
            this.filterByCategorie();
        },
        closeElementFilter(){
            bus.$emit("closeElementFilter");
        }
    },
    async created() {
        const response = await fetch(
            process.env.VUE_APP_API_HOST + "getAllCommunes"
        );
        let communes = await response.json();
        let sortedCommunes = communes;
        sortedCommunes = sortedCommunes.sort((a, b) => (a.nom > b.nom) ? 1 : ((b.nom > a.nom) ? -1 : 0))
        this.communes = sortedCommunes;
        this.communesNonFiltered = sortedCommunes;
        this.communes.push({ "nom": "Autre" });

        if (this.selectedCommunes.length != 0) this.selected = this.selectedCommunes;

    },
    computed: {
        selectedCommunes() {
            return store.selectedCommunes;
        },
        addFormOpen() {
            return store.addFormOpen;
        },
    }

}
</script>
  
<style>
@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');


.input {
    margin: 5px;

}

.checkboxes {
    max-height: 150px !important;
    min-height: 100px;
    overflow: auto;
    width: 90%;
    margin: auto;
    list-style: none;
    border: none !important;
    background-color: white;

}

/*input[type="checkbox"] {
    -webkit-appearance: none;

}*/

input[type="checkbox"]:checked {
    accent-color: #EF6C00 !important;
}

.filterHeader {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: 'Cooper Hewitt', sans-serif;
    font-weight: 707;
}

.filterTitle {
    font-weight: 707;
    font-family: 'Cooper Hewitt', sans-serif;
    color: #B66D00;
    font-size: 1.75rem !important;
}

.filterSubtitle {
    font-weight: 707;
    font-size: 1rem !important;

}

.labelChecklist {
    margin-left: 5px;
    font-family: 'Cooper Hewitt', sans-serif;
    font-weight: 707;
}

.btnZone {
    width: 100%;
    text-align: center;
}

.btnChecklist {
    margin: auto;
    color: white !important;
    font-weight: 707;
    font-family: 'Cooper Hewitt', sans-serif;
}

.searchBar {
    width: 90%;
    color: #B66D00 !important;
}

.communesSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

}

.communesSelected li {
    list-style: none;
    margin-left: 5px;
}

.communeLi::after {
    color: red;
    content: 'X ;';
}

.checkbox {
    text-align: left;
    display: flex;
    align-items: center;
}

.CustomCard {
    position: relative;
    top: 0;
    left: 70px;
    border-radius: 0 30px 30px 0 !important;
    background-color: #FFF2DF !important;
    z-index: 9999;
    height: 100vh;
}

.shiftedCard{
    position: relative;
    top: 0;
    left: 389px;
    
}

.ElementFilterCard {
    width: 23%;
}
</style>