<template>
    <div class="none">
    <div class="sidebar">
        <nav id="sidemenu">
                <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div> <v-btn @click="home" v-bind="attrs" v-on="on" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-home</v-icon>
                </v-btn> </div>
      </template>
      <span>Accueil</span>
    </v-tooltip>


            <div id="btnmenu">
                
                <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div> <v-btn v-bind="attrs" v-on="on" @click="fullsizemap" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-map</v-icon> </v-btn>
                 </div>
      </template>
      <span>Carte</span>
    </v-tooltip>

    <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div>  <v-btn v-bind="attrs" v-on="on" @click="fullsizetable" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-table</v-icon> </v-btn>
                 </div>
      </template>
      <span>Tableau</span>
    </v-tooltip>

    <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div>   <v-btn v-bind="attrs" v-on="on" @click="showElementFilter" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-checkbox-marked</v-icon>
                </v-btn> </div>
      </template>
      <p>Communes et catégories </p> 
      <p> Toponymes: {{this.count}}</p>
    </v-tooltip>

    <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div>  <v-btn v-bind="attrs" @click="showAddForm" v-on="on" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-map-marker</v-icon> </v-btn>
                 </div>
      </template>
      <span>Créer un point</span>
    </v-tooltip>

    <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div>  <v-btn v-bind="attrs" @click="exportSelection" v-on="on" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white">mdi-file-export</v-icon> </v-btn>
                 </div>
      </template>
      <span>Exporter la selection</span>
    </v-tooltip>

            </div>

            <div class="btnlogout"> 
                
                <v-tooltip right color="orange darken-2">
      <template v-slot:activator="{ on, attrs }">
        <div>  <v-btn v-bind="attrs" v-on="on" @click.prevent.stop="logout" class="navbtn" fab elevation="0" tile color="amber darken-2" style="width: 70px; height:70px; margin:0;"><v-icon color="white"
                        >mdi-logout</v-icon> </v-btn>
                 </div>
      </template>
      <span>Se déconnecter</span>
    </v-tooltip>
             </div>
        </nav>
    </div>
    <ElementFilter v-if="isElementFilter"/>
</div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ElementFilter from './ElementFilter.vue';
import {bus} from '../main'
import { store, mutations } from './Store';

export default {
    components: {
        ElementFilter
    },
   methods:{
    logout(){
            firebase.auth().signOut().then(() => {
              this.auth = false
               this.$router.replace('loginPage');
               
            });
         },
    showAddForm() {
        bus.$emit("addFormEvent");
    },
         
    fullsizemap () {
        var element = document.getElementById("mapContainer");
        element.classList.add("fullsizemap");
        var element2 = document.getElementById("test");
        element2.classList.remove("fullsizetable");
        bus.$emit("resizeMap");
    }, 
    fullsizetable () {
        var element = document.getElementById("test");
        element.classList.add("fullsizetable");
        var element2 = document.getElementById("mapContainer");
        element2.classList.remove("fullsizemap");
        bus.$emit("fullSizeTableEvent")
        bus.$emit("resizeMap");

    },  
    home () {
        var element = document.getElementById("test");
        element.classList.remove("fullsizetable");
        var element2 = document.getElementById("mapContainer");
        element2.classList.remove("fullsizemap");
        bus.$emit("homeEvent");
        bus.$emit("resizeMap");
    },
    showElementFilter () {
        this.isElementFilter= !this.isElementFilter ;
        mutations.setSelectCommunesOpen(this.isElementFilter);
        bus.$emit("elementFilterEvent");

    }    
   },

   async created() {
    bus.$on("addFormEvent",() => {
        this.isElementFilter= false;
    })
    bus.$on("ElementFilter",() => {
        this.isAddFormEvent= false;
    })
    bus.$on("closeElementFilter",() => {
        this.isElementFilter= false;
    })
   },
   async exportSelection(){
      const postMethod = {
          method: 'POST', 
          headers: {
            'Content-type': 'application/json; charset=UTF-8' 
          },
          body:JSON.stringify(this.selectedCommunes)
      }
      await fetch(
        process.env.VUE_APP_API_HOST + "export", postMethod
      );
    },
    computed: {
    count: function() {
        return store.toponymeCount;
    }
},
   data: function () {
    return {
    isElementFilter : false,
   }

  },
}
</script>



<style>

@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.sidebar {
    background-color: #FFA000;
    width: 70px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    text-align: center;
    font-weight: 707;
    font-family: 'Cooper Hewitt', sans-serif;
}


.none {
    height:0;
}
.btnlogout {
    bottom: 0;
    position: absolute;
}

.v-tooltip__content {
  opacity: 1 !important;
  font-weight: 707;
  font-family: 'Cooper Hewitt', sans-serif;

}

#btnmenu {
    position: absolute;
    transform: translateY(30%);
}

#sidemenu v-btn {
    width: 70px;
}

.fullsizemap {
    z-index: 500;
    width: 93vw !important;
    margin-right: 50px !important;

}

.fullsizetable {
    z-index: 500;
    width: 95vw !important;
    transform:translateX(-53.5%);
    background-color: white;
    height: 100%;
    padding: 0 30px;
}

</style>
