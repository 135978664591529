import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPlus)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

let app ="";
export const bus = new Vue();

  // Firebase configuration
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_STORAGE_MESSENGER_ID,
    appId: process.env.VUE_APP_FIREBASE_STORAGE_API_ID
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);



firebase.auth().onAuthStateChanged(()=>{
  if(!app){
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
})


