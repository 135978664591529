import Vue from "vue";

export const store = Vue.observable({
  position: {},
  zoom: 8,
  layer: "sat",
  cadastre: "",
  selectedCommunes: [],
  markerCommune: "",
  toponymeCount: 0,
  filtersSelected : [],
  wasFilterCardOpenedOnce : false,
  addFormOpen : false,
  selectCommunesOpen : false,
});

export const mutations = {
  setPosition(position) {
    store.position = position;
  },
  setZoom(zoom) {
    store.zoom = zoom;
  },
  setLayer(layer) {
    store.layer = layer;
  },
  setCadastre(cadastre) {
    store.cadastre = cadastre;
  },
  setcommunes(communes) {
    store.selectedCommunes = []
    communes.forEach(commune => {
      store.selectedCommunes.push(commune);
    });
  },
  setMarkerCommune(commune) {
    store.markerCommune = commune;
  },
  setFilterCard(array) {
    store.filtersSelected = array ;
  },
  setToponymeCount(tpNumber) {
    store.toponymeCount = tpNumber;
  },
  setWasFilterCardOpenedOnce (state) {
    store.wasFilterCardOpenedOnce = state;
  },
  setAddFormOpen (state) {
    store.addFormOpen = state;
  }
  ,
  setSelectCommunesOpen (state) {
    store.selectCommunesOpen = state;
  }
};