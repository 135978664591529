import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/LoginPage.vue')
  },
  {
    path: '/addPage',
    name: 'AddPage',

    component: () => import('../pages/AddPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/updatePage',
    name: 'UpdatePage',

    component: () => import('../pages/UpdatePage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/loginPage',
    name: 'LoginPage',
    
    component: () => import('../pages/LoginPage.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next('LoginPage');
  else if (!requiresAuth && currentUser) next('UpdatePage');
  else next();
});

export default router
