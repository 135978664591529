<template>
<v-app>
  <div>
    <SideHeader v-if = "!isLoginPage"/>
      <router-view></router-view>
  </div>
</v-app>
</template>

<script>
//import Map from './components/Map';
//import Board from './components/Board';
import SideHeader from './components/SideHeader.vue';
import firebase from 'firebase/compat/app';


export default {
  name: 'App',
  watch: {
    '$route'(to) {
if(to.name == "LoginPage") {
  this.isLoginPage = true;
}    }
  },

  components: {
   // Map,
    //Board
    SideHeader
  },

  data() {
    return {
       auth: false,
       isLoginPage: false,
      }
      
  },
  methods:{
    logout(){
            firebase.auth().signOut().then(() => {
              this.auth = false
               this.$router.replace('loginPage');
               
            });
         }
  },
  mounted(){
    if(firebase.auth().currentUser) this.auth = true;
    console.log(this.$route);

  }
};
</script>

<style>

body{
  background-color: gainsboro;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
}



body{
  margin: auto;
}

html{
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}
a {
    color: #34495e;
    text-decoration: none;
    cursor: pointer;
}

.navBar{
	padding-top: .5em;
	padding-bottom: .5em;
	border: 1px solid #a2a2a2;
	background-color: #f4f4f4;
	-webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
 
.navBar a:hover {
	color: #718daa;
}

.navElement{
  padding: 5px;
}


input:focus::placeholder {
  color: transparent;
}

.flat__btns {
  min-width: 60px;
  height: 35px;
  border: 1px solid;
  background: #fff;
  color: #000;
  border-radius: 2px;
  font-weight: 500;
  transition: all 200ms linear;
  box-sizing: border-box;
  outline: 0;
  font-size: 1rem;
}

a.flat__btns {
  text-decoration: none;
  line-height: 15px;
  font-size: 12px;
}

.flat__btns:hover {
  cursor: pointer;
  background-color: #eee;
}

.flat__btns--success {
  color: #4caf50;
}

.flat__btns--success:hover {
  border-color: #4caf50;
  background-color: #43A047;
  color: #fff;
}

.flat__btns--info {
  color: #2196f3;
}

.flat__btns--info:hover {
  border-color: #2196f3;
  background-color: #1E88E5;
  color: #fff;
}

.flat__btns--danger {
  color: #FF5722;
}

.flat__btns--danger:hover {
  border-color: #FF5722;
  background-color: #F4511E;
  color: #fff;
}

.flat__btns.flat__btns--disabled {
  color: #ccc;
  cursor: not-allowed;
}

.flat__btns--raised {
  box-shadow: 1px 2px 5px  rgba(0, 0, 0, 0.29);
  border: none;
}

.flat__btns--raised:active,
.flat__btns--fill:active {
  box-shadow: none;
}

.flat__btns--fill {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.29);
  border: none;
  color: #fff;
}

.flat__btns--success.flat__btns--fill {
  background-color: #4caf50;
}

.flat__btns--info.flat__btns--fill {
  background-color: #2196f3;
}

.flat__btns--danger.flat__btns--fill {
  background-color: #FF5722;
}

.flat__btns--dark {
  color: #fff;
  background: #212121;
  border-color: #222222;
}

.flat__btns--dark:hover {
  background: #212121;
  border-color: #222222;
}
</style>
